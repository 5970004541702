const initialState = {
    breaking_news: "",
    sliders: [],
    banners: [],
    services: [],
    bannerHome: [],
    servicesCategory:[],
    products: [],
    latest_articles: [],
    faqs: [],
    story: [],
    colleague_count:[],
    colleagues:[],
    team_members:[]
}

const UIReducer = (state = initialState, action) => {
    console.log(action.data)
    switch (action.type) {
        case 'UI':
            return {
                ...state,
                breaking_news: action.data ? action.data.breaking_news : "",
                sliders: action.data ? action.data.sliders : [],
                banners: action.data ? action.data.banners : [],
                services: action.data ? action.data.services : [],
                servicesCategory: action.data ? action.data.service_categories : [],
                bannerHome: action.data ? action.data.bannerHome : [],
                products: action.data ? action.data.products : [],
                FAQs: action.data ? action.data.faqs : [],
                questionsPage: action.data ? action.data.faqs : [],
                articles: action.data ? action.data.latest_articles : [],
                story: action.data ? action.data.story : [],
                colleague_count: action.data ? action.data.colleague_count : [],
                colleagues: action.data ? action.data.colleagues : [],
                team_members: action.data ? action.data.team_members : [],
            }
        default:
            return state
    }
}

export default UIReducer
